<template>
  <v-container>
    <v-row
      class="pt-3"
      width="100%"
    >
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Perguntas Frequentes</h1>
      </v-app-bar>

      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-text-field
              v-model="faq.title"
              label="Título"
              rounded
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <ckeditor
              v-model="faq.description"
              label="Observações"
              rounded
              dense
              outlined
              auto-grow
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              width="400px"
              @click="saveData"
              class="primary mr-4"
              rounded
              dense
            >
              <v-icon
                class="ml-2 pr-2"
                small
                >fas fa-save</v-icon
              >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        lg="12"
      >
        <v-row>
          <v-col
            cols="12"
            lg="7"
          >
            <v-list class="elevation-2">
              <v-list-item
                v-for="(item, idx) in faqs"
                :key="idx"
                @click="setFaqObj(item)"
              >
                <v-list-item-content>
                  <v-list-item-title> {{ item.title }} </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon
                      color="primary"
                      small
                      >fas fa-edit</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action> </v-list-item-action>
              </v-list-item>
              <v-dialog
                v-model="faqDialog"
                max-width="500"
              >
                <v-card>
                  <v-card-title class="primary white--text">
                    {{ faqObj.title }}
                  </v-card-title>
                  <v-card-text class="pt-5">
                    <v-text-field
                      v-model="faqObj.title"
                      label="Título da pergunta"
                      rounded
                      outlined
                      dense
                    />
                    <ckeditor
                      v-model="faqObj.description"
                      label="Observações"
                      rounded
                      dense
                      outlined
                      auto-grow
                    />
                  </v-card-text>
                  <v-card-actions
                    width="100%"
                    class="justify-space-between"
                  >
                    <!-- <v-spacer /> -->
                    <div>
                      <v-btn
                        icon
                        rounded
                        @click="deleteFaq(faqObj)"
                      >
                        <v-icon
                          color="red"
                          small
                          >fas fa-trash</v-icon
                        >
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        @click="faqDialog = false"
                        outlined
                        rounded
                        color="primary"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        rounded
                        color="primary"
                        @click="updateFaq(faqObj)"
                      >
                        Salvar
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  mixins: [checkProfileRoutePermission],
  data: () => ({
    faqDialog: false,
    faq: {},
    faqs: [],
    faqObj: {},
  }),
  methods: {
    setFaqObj(item) {
      this.faqObj = item
      this.faqDialog = true
    },
    getFaqs() {
      this.$api
        .get('site/faqs', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.faqs = res.data.data
          this.faqs.map((item) => {
            item.faqDialog = false
          })
          console.log(this.faqs)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateFaq(faqObj) {
      this.$api
        .post('site/faq/update', faqObj)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'FAQ atualizado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.faqDialog = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteFaq(item) {
      this.$api
        .post('site/faq/delete', item)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'FAQ deletado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
              this.removeFaq(item)
            },
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeFaq(item) {
      let idx = this.faqs.indexOf(item)
      this.faqs.splice(idx, 1)
    },
    saveData() {
      this.$api
        .post('site/faq', this.faq)
        .then(() => {
          this.faq.title = null
          this.faq.description = null

          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'FAQ cadastrado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.faqDialog = false
          this.getFaqs()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getFaqs()
  },
}
</script>

<style></style>
